import React from 'react';

/**
 * Checks date and if date < length add character before date
 *
 * @param {number} date - date params
 * @param {number} length - desired date length
 * @param {number} character - parameter which is added if date < length
 */
export const dateFormattedFun = (date, length, character) => {
    if (
        !date ||
        date === NaN ||
        typeof date !== 'number' ||
        typeof length !== 'number' ||
        typeof character !== 'number'
    ) {
        return false;
    }

    return new Array(length - date.toString().length + 1).join(character) + date;
};

export const loader = (
    <div className='showbox'>
        <div className='loader'>
            <svg className='circular' viewBox='25 25 50 50'>
                <defs>
                    <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
                        <stop offset='0%' stopColor='#00b4db' />
                        <stop offset='100%' stopColor='#0083b0' />
                    </linearGradient>
                </defs>
                <circle
                    className='path'
                    cx={50}
                    cy={50}
                    r={20}
                    fill='none'
                    stroke='url(#gradient)'
                    strokeWidth='2.5'
                    strokeMiterlimit={10}
                />
            </svg>
        </div>
    </div>
);

export const createMarkup = html => {
    return { __html: html };
};

export const stickyScroll = (item, itemFixed) => {
    const staticFooterHeight = !!document.getElementById('footer-wrapper')
        ? document.getElementById('footer-wrapper').offsetHeight
        : 0;

    window.onscroll = function(e) {
        let staticAppHeight = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight,
            ),
            scroll =
                window.pageYOffset !== undefined
                    ? window.pageYOffset
                    : (document.documentElement || document.body.parentNode || document.body)
                          .scroll;

        if (
            scroll <=
            staticAppHeight - window.screen.height - itemFixed.offsetHeight - staticFooterHeight
        ) {
            item.style.height = `${itemFixed.offsetHeight}px`;
            itemFixed.style.position = 'fixed';
        } else {
            itemFixed.style.position = 'relative';
            item.style.height = 0;
        }
    };
};

export const countriesStates = [
    {
        name: 'Alabama',
        abbreviation: 'AL',
    },
    {
        name: 'Alaska',
        abbreviation: 'AK',
    },
    {
        name: 'American Samoa',
        abbreviation: 'AS',
    },
    {
        name: 'Arizona',
        abbreviation: 'AZ',
    },
    {
        name: 'Arkansas',
        abbreviation: 'AR',
    },
    {
        name: 'California',
        abbreviation: 'CA',
    },
    {
        name: 'Colorado',
        abbreviation: 'CO',
    },
    {
        name: 'Connecticut',
        abbreviation: 'CT',
    },
    {
        name: 'Delaware',
        abbreviation: 'DE',
    },
    {
        name: 'District Of Columbia',
        abbreviation: 'DC',
    },
    {
        name: 'Federated States Of Micronesia',
        abbreviation: 'FM',
    },
    {
        name: 'Florida',
        abbreviation: 'FL',
    },
    {
        name: 'Georgia',
        abbreviation: 'GA',
    },
    {
        name: 'Guam',
        abbreviation: 'GU',
    },
    {
        name: 'Hawaii',
        abbreviation: 'HI',
    },
    {
        name: 'Idaho',
        abbreviation: 'ID',
    },
    {
        name: 'Illinois',
        abbreviation: 'IL',
    },
    {
        name: 'Indiana',
        abbreviation: 'IN',
    },
    {
        name: 'Iowa',
        abbreviation: 'IA',
    },
    {
        name: 'Kansas',
        abbreviation: 'KS',
    },
    {
        name: 'Kentucky',
        abbreviation: 'KY',
    },
    {
        name: 'Louisiana',
        abbreviation: 'LA',
    },
    {
        name: 'Maine',
        abbreviation: 'ME',
    },
    {
        name: 'Marshall Islands',
        abbreviation: 'MH',
    },
    {
        name: 'Maryland',
        abbreviation: 'MD',
    },
    {
        name: 'Massachusetts',
        abbreviation: 'MA',
    },
    {
        name: 'Michigan',
        abbreviation: 'MI',
    },
    {
        name: 'Minnesota',
        abbreviation: 'MN',
    },
    {
        name: 'Mississippi',
        abbreviation: 'MS',
    },
    {
        name: 'Missouri',
        abbreviation: 'MO',
    },
    {
        name: 'Montana',
        abbreviation: 'MT',
    },
    {
        name: 'Nebraska',
        abbreviation: 'NE',
    },
    {
        name: 'Nevada',
        abbreviation: 'NV',
    },
    {
        name: 'New Hampshire',
        abbreviation: 'NH',
    },
    {
        name: 'New Jersey',
        abbreviation: 'NJ',
    },
    {
        name: 'New Mexico',
        abbreviation: 'NM',
    },
    {
        name: 'New York',
        abbreviation: 'NY',
    },
    {
        name: 'North Carolina',
        abbreviation: 'NC',
    },
    {
        name: 'North Dakota',
        abbreviation: 'ND',
    },
    {
        name: 'Northern Mariana Islands',
        abbreviation: 'MP',
    },
    {
        name: 'Ohio',
        abbreviation: 'OH',
    },
    {
        name: 'Ontario',
        abbreviation: 'ON',
    },
    {
        name: 'Oklahoma',
        abbreviation: 'OK',
    },
    {
        name: 'Oregon',
        abbreviation: 'OR',
    },
    {
        name: 'Palau',
        abbreviation: 'PW',
    },
    {
        name: 'Pennsylvania',
        abbreviation: 'PA',
    },
    {
        name: 'Puerto Rico',
        abbreviation: 'PR',
    },
    {
        name: 'Rhode Island',
        abbreviation: 'RI',
    },
    {
        name: 'South Carolina',
        abbreviation: 'SC',
    },
    {
        name: 'South Dakota',
        abbreviation: 'SD',
    },
    {
        name: 'Tennessee',
        abbreviation: 'TN',
    },
    {
        name: 'Texas',
        abbreviation: 'TX',
    },
    {
        name: 'Utah',
        abbreviation: 'UT',
    },
    {
        name: 'Vermont',
        abbreviation: 'VT',
    },
    {
        name: 'Virgin Islands',
        abbreviation: 'VI',
    },
    {
        name: 'Virginia',
        abbreviation: 'VA',
    },
    {
        name: 'Washington',
        abbreviation: 'WA',
    },
    {
        name: 'West Virginia',
        abbreviation: 'WV',
    },
    {
        name: 'Wisconsin',
        abbreviation: 'WI',
    },
    {
        name: 'Wyoming',
        abbreviation: 'WY',
    },
    {
        name: 'Alberta',
        abbreviation: 'AB',
    },
    {
        name: 'British Columbia',
        abbreviation: 'BC',
    },
    {
        name: 'Manitoba',
        abbreviation: 'MB',
    },
    {
        name: 'New Brunswick',
        abbreviation: 'NB',
    },
    {
        name: 'Newfoundland and Labrador',
        abbreviation: 'NL',
    },
    {
        name: 'Nova Scotia',
        abbreviation: 'NS',
    },
    {
        name: 'Ontario',
        abbreviation: 'ON',
    },
    {
        name: 'Prince Edward Island',
        abbreviation: 'PE',
    },
    {
        name: 'Quebec',
        abbreviation: 'QC',
    },
    {
        name: 'Saskatchewan',
        abbreviation: 'SK',
    },
];