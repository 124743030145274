import environment from "./base";

const domainUrl = "localhost";
// const baseApi = 'https://connectors.aprilaire-data.dev-directory.com'
const baseApi = "https://dashboard.aprilairepartners.com";

const env = environment(baseApi, domainUrl);

/*eslint-disable */
export default {
  ...env,
  intervals: {
    ...env.intervals,
    logout: 300, // 5 min in seconds
  },
};
/*eslint-enable */
