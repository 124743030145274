import { useEffect, useState } from "react";

import $ from "jquery";
import environment from "environment";
import { getGlobal } from "@api";
import { createMarkup } from "@helpers/utils";

const GlobalHeader = () => {
  const [globalHeader, setGlobalHeader] = useState();
  const [globalCSS, setGlobalCSS] = useState();

  useEffect(() => {
    initializeCustomData();
    loadData();
  }, []);

  const initializeCustomData = () => {
    // Expose jQuery to window so the dynamically script that uses jQuery won't throw undefined.
    window.$ = $;
  };

  const loadData = async () => {
    getGlobal(environment.S3_URL + environment.HEADER).then((response) =>
      setGlobalHeader(response)
    );

    getGlobal(
      environment.CLOUDFRONT_URL + "/template.css?v=" + environment.DEPLOY_DATE
    ).then((response) => setGlobalCSS(response));
    getGlobalJS();
  };

  const getGlobalJS = async () => {
    const script = document.createElement("script");

    script.src =
      environment.CLOUDFRONT_URL + "/template.js?v=" + environment.DEPLOY_DATE;
    script.async = true;

    document.body.appendChild(script);
  };

  return (
    <>
      <style dangerouslySetInnerHTML={createMarkup(globalCSS)} />
      <div dangerouslySetInnerHTML={createMarkup(globalHeader)} />
    </>
  );
};
export default GlobalHeader;
