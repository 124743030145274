import { ToastContainer } from "react-toastify";

import GlobalHeader from "@components/GlobalHeader";
import GlobalFooter from "@components/GlobalFooter";

import Layout from "@components/Layout";
import PicId from "@components/PicId";

import "@assets/scss/style.scss";

const AprilairePartnersDashboard = () => {
  return (
    <>
      <GlobalHeader />
      <Layout>
        <PicId />
      </Layout>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <GlobalFooter />
    </>
  );
};

/**
 * This configuration is initialized automaticaly by redux using App from next/app
 */
export default AprilairePartnersDashboard;
