export const getNumberWithSign = (num, sign) => {
    let val;
    switch (sign) {
        case '%': 
            val = `${num}${sign}`; 
            break;
        case '$': 
            val = formatter.format(num); 
            break;
        default: 
            val = parseInt(num);
            break;  
    }
    return val;
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const verifyEmail = (email) => {

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return (true)

    return (false) 
}

export const getParamsAttribute = (name) => {
    switch(name) {
        case 'FTPercentageOfHomes':
            return 'freePercentageOfHomes';
        case 'FMEstimatedCostPerInstall':
            return 'freeEstimatedCostPerInstall';
        case 'FMGM': 
            return 'freeGm';
        case 'STPercentageOfHomes':
            return 'soldPercentageOfHomes';
        case 'STPricePerKitToHomeowner':
            return 'soldPricePerKit';
        case 'SMEstimatedCostPerInstall':
            return 'soldEstimatedCostPerInstall';
        case 'SMGM':
            return 'soldGm';
        default: 
            return name;
    }
}

export const verifyParamRange = (name, value) => {
    let paramName = getParamsAttribute(name);
    
    switch(paramName) {
        case 'freePercentageOfHomes':
            if(parseInt(value) >= 10 && parseInt(value) <= 50) {return false;} return true;
                
        case 'freeEstimatedCostPerInstall':
            if(parseInt(value) == 1200) {return false} return true;

        case 'freeGm':
            if(parseInt(value) >= 40 && parseInt(value) <= 90) {return false;} return true;

        case 'soldPercentageOfHomes':
                if(parseInt(value) >= 60 && parseInt(value) <= 90) {return false;} return true;

        case 'soldPricePerKit':
            if(parseInt(value) <= 30 && parseInt(value) >= 55) {return false;} return true;
            
        case 'soldEstimatedCostPerInstall':
            if(parseInt(value) >= 800 && parseInt(value) <= 1500) {return false;} return true;

        case 'soldGm':
            if(parseInt(value) >= 25 && parseInt(value) <= 60) {return false;} return true;

        case 'serviceVisitsPerYear':
            if(parseInt(value) >= 0) {return false;} return true;

        case 'installVisitsPerYear':
            if(parseInt(value) >= 0) {return false;} return true;

        case 'maintenanceVisitsPerYear':
            if(parseInt(value) >= 0){return false;} return true;

        case 'percentageOfTestedHomes':
            if(parseInt(value) >= 0 && parseInt(value) <= 100) {return false;} return true;

        case 'percentageThatMitigate':
            if(parseInt(value) >= 0 && parseInt(value) <= 100) {return false;} return true;
    }
}

export const paramRangeText = (name, type) => {
   switch(type) {
       case 1: 
            switch(name) {
                case 'freePercentageOfHomes':
                    return 'Data is out of range 0 - 100';
                case 'freeEstimatedCostPerInstall':
                    return 'Data is out of range 0 - 1200'
                case 'freeGm':
                    return 'Data is out of Range 0 - 100'
            }
        case 2: 
            switch(name) {
                case 'soldPercentageOfHomes':
                    return 'Data is out of Range 0 - 100';
                case 'soldPricePerKit':
                    return 'Data must be greater than or equal to 0';
                case 'soldEstimatedCostPerInstall':
                    return 'Data must be greater than or equal to 0';
                case 'soldGm':
                    return 'Data must be greater than or equal to 0';
            }
   }
}