import axios from "axios";
import environment from "environment";

const path = environment.API_HOST + environment.API_PATH;

export const get = (apiEndpoint, params) => {
  return axios.get(path + apiEndpoint, { params }).then((response) => {
    return response.data;
  });
};

export const post = (apiEndpoint, payload, config) => {
  return axios.post(path + apiEndpoint, payload, config).then((response) => {
    return response.data;
  });
};

export const getGlobal = (apiEndpoint, params) => {
  return axios.get(apiEndpoint, { params }).then((response) => {
    return response.data;
  });
};
