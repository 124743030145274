import cookie from 'js-cookie';

export const setCookie = (key, value, saveAccount) => {
    if (process.browser) {
        cookie.set(key, value, {
            expires: !!saveAccount ? 30 : 1,
            path: '/',
            // domain: '.' + mainDomain,
        });
    }
};

export const removeCookie = key => {
    if (process.browser) {
        cookie.remove(key, {
            expires: 1,
            path: '/',
            // domain: '.' + mainDomain,
        });
    }
};

export const getCookie = (key, req) => {
    return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req);
};

const getCookieFromBrowser = key => {
    return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
    if (!req.headers.cookie) {
        return undefined;
    }
    const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`));
    if (!rawCookie) {
        return undefined;
    }
    return rawCookie.split('=')[1];
};
