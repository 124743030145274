/*eslint-disable */
export default function (baseApi, domainUrl) {
  return {
    PORT: 3000,
    // API_HOST: "http://ap-dash-api.dev-directory.com",
    API_HOST: "https://dashboard.aprilairepartners.com",
    API_PATH: "/api/v1",
    API_HOST_CALCULATOR: "https://api-calculator.dev-directory.com/api/v1",
    MAIN_DOMAIN: "dev-directory.com",
    // S3_URL: "//aprilaire-partners-staging.s3-us-west-2.amazonaws.com",
    S3_URL: "https://static.aprilairepartners.com",
    // CLOUDFRONT_URL: "//dz77gcd91q4m2.cloudfront.net",
    CLOUDFRONT_URL: "https://static.aprilairepartners.com",
    CLOUDFRONT_DIST_PATH: "/dashboard/dist",
    GA_ID: "UA-XXXXXXX-3",
    FOOTER: "/footer-2022.shtml",
    HEADER: "/header-2022.shtml",
    DEPLOY_DATE: "20220711",
    domainUrl: domainUrl,
    GET_PICID: "/authentication/picid",

    cookies: {
      expiration: 30,
    },
    intervals: {
      logout: 3600, // 1 hour in seconds
    },
    api: {
      baseApi: `${baseApi}/api/v1`,
      dashboard: `${baseApi}/api/v1/dashboards`,
      schedule: `${baseApi}/api/v1/schedule`,
    },
    token: {
      auth: process.env.AUTH_TOKEN,
    },
    logoutRedirectTimeout: 2e3,
    listsLimit: 5,
    dateFormat: "MM/DD/yyyy",
    dateTimeFormat: "MM/DD/yyyy HH:mm",
    shortDateTimeFormat: "MM/DD/YY",
    campaignDateTimeFormat: "YYYY-MM-DD",
    historyLog: {
      itemsPerPage: 10,
    },
  };
}
/*eslint-enable */
