import React, { Component } from "react";
import PropTypes from "prop-types";

import { removeCookie } from "@helpers/cookies";
import classNames from "classnames";

import "./style.scss";

class Title extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
  };

  deauthenticate = () => {
    const { deauthenticate } = this.props;

    deauthenticate()
      .then((res) => {
        // Router.pushRoute('/login');
      })
      .catch((error) => {
        // Router.pushRoute('/login');
      })
      .finally(() => {
        removeCookie("username");
        removeCookie("accessToken");
        removeCookie("id");
        removeCookie("userId");
        removeCookie("role");
        removeCookie("picId");
        removeCookie("picIdPrime");
        removeCookie("isCoopAdv");
        removeCookie("isLiterature");
        removeCookie("isQuotingTools");
      });
  };

  changeFormElement = async (event) => {
    const target = event.target;

    switch (target.value) {
      case "Welcome":
        // Router.pushRoute('/welcome');
        break;

      case "Dashboard":
        // Router.pushRoute('/dash');
        break;

      case "Profile":
        // Router.pushRoute('/profile');
        break;
      case "Manage Account":
        // Router.pushRoute('/manage-users');
        break;
    }
  };

  getFormElem = (value) => {
    switch (value) {
      case "Welcome":
        return (
          <React.Fragment>
            <option value="" disabled hidden>
              Welcome
            </option>
            <option value="Dashboard">Dashboard</option>
            <option value="Profile">Profile</option>
            {/* <option value='Manage Account'>Manage Associates</option> */}
          </React.Fragment>
        );
        break;

      case "Dashboard":
        return (
          <React.Fragment>
            <option value="" disabled hidden>
              Dashboard
            </option>
            <option value="Welcome">Welcome</option>
            <option value="Profile">Profile</option>
            {/* <option value='Manage Account'>Manage Associates</option> */}
          </React.Fragment>
        );
        break;

      case "Profile":
        return (
          <React.Fragment>
            <option value="" disabled hidden>
              Profile
            </option>
            <option value="Welcome">Welcome</option>
            <option value="Dashboard">Dashboard</option>
            {/* <option value='Manage Account'>Manage Associates</option> */}
          </React.Fragment>
        );
        break;
      case "Manage Account":
        return (
          <React.Fragment>
            <option value="" disabled hidden>
              Manage Associates
            </option>
            <option value="Welcome">Welcome</option>
            <option value="Dashboard">Dashboard</option>
            <option value="Profile">Profile</option>
          </React.Fragment>
        );
        break;
    }
  };

  render() {
    const {
      title,
      subtitle,
      manage,
      subTitleLink,
      subTitleLinkText,
      subTitleLinkNl,
      roiCalculator,
      maxWidth,
    } = this.props;

    return (
      <div className="title-component  title-dashboard text-center relative">
        <div className="container">
          <div className="row margin-bottom-4 margin-bottom-1--xs">
            <div className="margin-top-1 display-block--xs display-flex align-items-center">
              <div
                className="title no-padding-xs"
                style={
                  !!manage
                    ? {
                        margin: "1rem",
                        width: "100%",
                        paddingLeft: "160px",
                      }
                    : { margin: "1rem", width: "100%" }
                }
              >
                <h1
                  style={{
                    marginBottom:
                      roiCalculator === true && !subtitle ? "0px" : "",
                  }}
                >
                  {title}
                </h1>
              </div>
              <div className="select-section">
                {manage && (
                  <form className="">
                    <div className="select-dashboard">
                      <select
                        name="selectComplete"
                        id="joinMembership"
                        defaultValue={""}
                        onChange={(e) => this.changeFormElement(e)}
                      >
                        {this.getFormElem(manage)}
                      </select>
                    </div>
                  </form>
                )}
              </div>
            </div>
            {!!subtitle && (
              <div className="col-sm-12 layout-row justify-content-center">
                <div
                  className="wrapper"
                  className={classNames({
                    "wrapper roi-calculator": roiCalculator === true,
                    wrapper: !roiCalculator,
                  })}
                  style={{ maxWidth: maxWidth ? `${maxWidth}px` : "" }}
                >
                  <span>
                    {subtitle}
                    {!!subTitleLinkNl && <br />}
                    {!!subTitleLink && (
                      <React.Fragment>
                        <a href={subTitleLink} target="_blank">
                          {" "}
                          {subTitleLinkText}
                        </a>
                        .
                      </React.Fragment>
                    )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="bk-waves">
          <span className="sr-only">Waves background overlay</span>
        </div>
      </div>
    );
  }
}

Title.defaultProps = {
  auth: {},
};

Title.propTypes = {
  auth: PropTypes.object.isRequired,
  deauthenticate: PropTypes.func,
  manage: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Title;
