import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { toast } from "react-toastify";
import environment from "environment";
import { post } from "@api";
import Title from "@components/Title";
import { verifyEmail } from "@helpers/roi/utils";

import "./style.scss";

const PicId = () => {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false,
  });
  const [isCopied, setIsCopied] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPicId, setShowPicId] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [picId, setPicId] = useState("");

  const handleChange = (name, value) => {
    setForm((form) => {
      return { ...form, [name]: value != "" ? value : null };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let errors = { username: false, password: false };

    setShowPicId(false);
    setIsSubmitting(true);

    if (!form.username) {
      errors = { ...errors, username: "Email field is required." };
      setIsSubmitting(false);
    } else if (!verifyEmail(form.username)) {
      errors = { ...errors, username: "Invalid email address." };
      setIsSubmitting(false);
    }

    if (!form.password) {
      errors = { ...errors, password: "Password field is required." };
      setIsSubmitting(false);
    }

    setFormErrors(errors);

    if (!errors.username && !errors.password) {
      post(environment.GET_PICID, form)
        .then((response) => {
          setShowPicId(true);
          setPicId(response.picid);
          setIsSubmitting(false);
        })
        .catch((error) => {
          let message = `${error?.response?.data?.errors?.userMessage?.password}`;
          errors = { username: true, password: message };
          setFormErrors(errors);
          toast.error(error.message);
        });
    }
  };

  const handleCopyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(picId);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const faEyeStyle = {
    fontSize: 16,
    position: "absolute",
    right: 13,
    top: 43,
    color: "#bebebe",
    width: "18px",
    cursor: "pointer",
  };

  const faCircleNotchStyle = {
    top: "calc(50% - .5em)",
    right: 10,
    position: "absolute",
    color: "#000",
    width: "1em",
    height: "1em",
  };

  return (
    <>
      <Title
        title="Find Your PICID"
        subtitle="Enter your AprilAire Partner credentials to find your PICID. If no PICID is shown, please reach out to AprilAire customer service at support@aprilairepartners.com"
        maxWidth={1100}
      />
      <div className="picid-container">
        <div className="picid-form">
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-block">
              <label className="margin-bottom-2">Email</label>
              <input
                type="email"
                name="username"
                onChange={(e) => handleChange("username", e.target.value)}
                className={formErrors.username !== false ? "error" : ""}
              />
              {formErrors.username !== false ? (
                <p className="error">{formErrors.username}</p>
              ) : null}
            </div>
            <div className="form-block">
              <label className="margin-bottom-2">Password</label>
              <input
                type={!showPassword ? "password" : "text"}
                name="password"
                onChange={(e) => handleChange("password", e.target.value)}
                className={formErrors.password !== false ? "error" : ""}
              />
              <FontAwesomeIcon
                onClick={() => setShowPassword(!showPassword)}
                icon={showPassword ? faEyeSlash : faEye}
                style={faEyeStyle}
              />
              {formErrors.password !== false ? (
                <p className="error">{formErrors.password}</p>
              ) : null}
            </div>
            <button
              className={classNames("submit-picid", {
                disabled: isSubmitting,
              })}
            >
              Find PICID
              {isSubmitting && (
                <div className="loading">
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    spin
                    style={faCircleNotchStyle}
                    rotation={90}
                  />
                </div>
              )}
            </button>
          </form>
        </div>
        {picId && showPicId && (
          <div className="picid" onClick={handleCopyToClipboard}>
            <div className="picid-find">
              {picId} <div className="copy">copy</div>
            </div>
            {isCopied && <div className="alert">Copied!</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default PicId;
