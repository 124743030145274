import { useEffect, useState } from "react";
import environment from "environment";
import { getGlobal } from "@api";
import { createMarkup } from "@helpers/utils";

const GlobalFooter = () => {
  const [globalFooter, setGlobalFooter] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    getGlobal(environment.S3_URL + environment.FOOTER).then((result) =>
      setGlobalFooter(result)
    );
  };

  return <div dangerouslySetInnerHTML={createMarkup(globalFooter)}></div>;
};

export default GlobalFooter;
